import React from 'react';
import classNames from 'classnames/bind';

import styles from './content-page.module.css';

const cx = classNames.bind(styles);

export type ContentPageProps = {
  children: React.ReactNode;
};

export default function ContentPage({ children }: ContentPageProps) {
  return (
    <span className={cx('background')}>
      <article className={cx('content')}>
        {children}

        <footer className={cx('footer')} />
      </article>
    </span>
  );
}
