import React from 'react';
import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGithub,
  faTwitter,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import { PageProps } from 'gatsby';

import SEO from '../components/seo';
import ContentPage from '../components/content-page';
import styles from './about.module.css';

const cx = classNames.bind(styles);

export default function About({ location }: PageProps) {
  return (
    <ContentPage>
      <SEO title="Dan Freeman" location={location} />

      <header className={cx('header')}>
        <img src="/images/dfreeman.jpg" className={cx('image')} />
        <div className={cx('headerDetails')}>
          <h1 className={cx('title')}>Dan Freeman</h1>
          <div>
            <a
              href="https://github.com/dfreeman"
              className={cx('socialLink')}
              rel="noopener"
            >
              <FontAwesomeIcon icon={faGithub} />
            </a>
            <a
              href="https://twitter.com/__dfreeman"
              className={cx('socialLink')}
              rel="noopener"
            >
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a
              href="https://www.linkedin.com/in/dan-freeman"
              className={cx('socialLink')}
              rel="noopener"
            >
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </div>
          <div className={cx('pronouns')}>
            (
            <a href="http://pronoun.is/he" rel="noopener">
              he/him
            </a>
            )
          </div>
        </div>
      </header>

      <section>
        <p>
          Hi! I'm Dan, and I'm a Software Developer based in Zürich,
          Switzerland.
        </p>
        <p>
          I'm currently working as a frontend architect on{' '}
          <a href="https://salsify.com" rel="noopener">
            Salsify
          </a>
          's Product Experience Management platform. I also occasionally write
          for the{' '}
          <a href="https://www.salsify.com/blog/engineering" rel="noopener">
            Salsify engineering blog
          </a>
          .
        </p>
        <p>
          I can be found as some variant of <code>dfreeman</code> on most of the
          internet, most frequently on{' '}
          <a href="https://github.com/dfreeman" rel="noopener">
            GitHub
          </a>{' '}
          and{' '}
          <a href="https://twitter.com/__dfreeman" rel="noopener">
            Twitter
          </a>
          . I also have a mostly-neglected{' '}
          <a href="https://www.linkedin.com/in/dan-freeman/" rel="noopener">
            LinkedIn profile
          </a>{' '}
          if you're into that kind of thing, but I'm not looking for any
          euphemistic "new opportunities" at the moment.
        </p>
        <p>
          Outside software, I enjoy running on the small «Berge» all over Zürich
          and growing{' '}
          <a href="https://www.instagram.com/p/B0yfTB6jLKl" rel="noopener">
            tiny vegetables
          </a>{' '}
          on our tiny balcony. I'm also trying to wrap my head around the Swiss
          dialect of German, but it remains to be seen if that's an attainable
          goal.
        </p>
      </section>
    </ContentPage>
  );
}
