import React from 'react';
import classNames from 'classnames/bind';

import { BlogPost, BlogTag } from '../types/schema';
import styles from './posts-list.module.css';
import { Link } from 'gatsby';
import PostSeparator from './post-separator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const cx = classNames.bind(styles);

export type PostsListProps = {
  posts: Array<
    Pick<BlogPost, 'title' | 'slug' | 'teaser' | 'date'> & {
      tags: Array<Pick<BlogTag, 'icon' | 'primaryColor' | 'secondaryColor'>>;
    }
  >;
};

export function PostsList({ posts }: PostsListProps) {
  return (
    <div className={cx('postList')}>
      {posts.map((post, index) => {
        let primaryTag = post.tags[0];

        return (
          <Link
            to={`/${post.slug}`}
            key={post.slug}
            className={cx('post', index % 6 === 0 && 'wide')}
          >
            <span
              className={cx('postSigil')}
              style={{
                color: primaryTag.secondaryColor,
                backgroundColor: primaryTag.primaryColor,
              }}
            >
              <span className={cx('emoji')} role="presentation">
                {primaryTag.icon}
              </span>
            </span>

            <section className={cx('summary')}>
              <time className={cx('time')} dateTime={post.date}>
                {new Date(post.date).toLocaleDateString(undefined, {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })}
              </time>

              <h2 className={cx('title')}>{post.title}</h2>

              <PostSeparator tag={primaryTag} />

              <span className={cx('excerpt')}>{post.teaser}</span>

              <span className={cx('readMore')}>
                <span className={cx('readMoreLink')}>Read More</span>

                <FontAwesomeIcon
                  className={cx('readMoreChevron')}
                  style={{ color: primaryTag.secondaryColor, width: '8px' }}
                  icon={faChevronRight}
                  size="sm"
                />
              </span>
            </section>
          </Link>
        );
      })}
    </div>
  );
}
