import React, { useState } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faGithub } from '@fortawesome/free-brands-svg-icons';
import {
  faBars,
  faTimes,
  faPenNib,
  faAddressCard,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';

import styles from './layout.module.css';

import 'typeface-raleway';
import 'typeface-muli';

const cx = classNames.bind(styles);

export type LayoutProps = {
  children: React.ReactNode;
};

export default function Layout({ children }: LayoutProps) {
  let [navOpen, setNavOpen] = useState(false);
  let data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <main
        id="main"
        className={cx('main', { navOpen })}
        onClick={() => setNavOpen(false)}
      >
        <button
          title="Expand Navigation"
          className={cx('toggle', 'right', { navOpen })}
          onClick={event => {
            event.stopPropagation();
            setNavOpen(!navOpen);
          }}
        >
          <FontAwesomeIcon icon={faBars} size="2x" />
        </button>

        <Link
          to="/"
          title="Home"
          className={cx('toggle', 'left')}
          activeClassName={cx('hidden')}
        >
          <FontAwesomeIcon icon={faChevronLeft} size="2x" />
        </Link>

        {children}
      </main>

      <nav
        className={cx('nav', { navOpen })}
        onClick={event => {
          if (
            event.target instanceof HTMLElement &&
            event.target.tagName === 'A'
          ) {
            setNavOpen(false);
          }
        }}
      >
        <button
          title="Collapse Navigation"
          className={cx('toggle', 'right', 'light')}
          onClick={event => {
            event.stopPropagation();
            setNavOpen(!navOpen);
          }}
        >
          <FontAwesomeIcon icon={faTimes} size="2x" />
        </button>

        <h2 className={cx('title')}>{data.site.siteMetadata.title}</h2>

        <Link to="/" className={cx('navItem')} activeClassName={cx('current')}>
          <FontAwesomeIcon icon={faPenNib} className={cx('navIcon')} /> Home
        </Link>

        <Link
          to="/about"
          className={cx('navItem')}
          activeClassName={cx('current')}
        >
          <FontAwesomeIcon icon={faAddressCard} className={cx('navIcon')} />{' '}
          About Me
        </Link>

        <a
          href="https://twitter.com/__dfreeman"
          className={cx('navItem')}
          target="_blank"
          rel="noopener"
        >
          <FontAwesomeIcon icon={faTwitter} className={cx('navIcon')} /> Twitter
        </a>

        <a
          href="https://github.com/dfreeman"
          className={cx('navItem')}
          target="_blank"
          rel="noopener"
        >
          <FontAwesomeIcon icon={faGithub} className={cx('navIcon')} /> GitHub
        </a>
      </nav>
    </>
  );
}
