import React from 'react';
import classNames from 'classnames/bind';

import styles from './index-page.module.css';

const cx = classNames.bind(styles);

export type IndexPageProps = {
  title: string;
  description: React.ReactNode;
  children?: React.ReactNode;
};

export default function IndexPage({
  children,
  title,
  description,
}: IndexPageProps) {
  return (
    <>
      <header className={cx('header')}>
        <h1 className={cx('title')}>{title}</h1>
        <hr className={cx('divider')} />
        <div className={cx('description')}>{description}</div>
      </header>

      <div className={cx('content')}>{children}</div>
    </>
  );
}
