import React from 'react';

export type PostSeparatorProps = {
  tag: {
    primaryColor: string;
    secondaryColor: string;
  };
};

export default function PostSeparator({ tag }: PostSeparatorProps) {
  return (
    <hr
      style={{
        width: '45px',
        borderStyle: 'solid',
        borderWidth: '4px 0',
        margin: '16px 0 20px',
        borderTopColor: tag.primaryColor,
        borderBottomColor: tag.secondaryColor,
      }}
    />
  );
}
