import React from 'react';
import { Link } from 'gatsby';
import IndexPage from '../components/index-page';

export default function NotFoundPage() {
  return (
    <IndexPage
      title="Oops"
      description={
        <>
          <p>There doesn't seem to be anything here...</p>
          <p>
            Maybe <Link to="/">go home</Link>?
          </p>
        </>
      }
    />
  );
}
