import React from 'react';
import { graphql, PageProps, Link } from 'gatsby';
import classNames from 'classnames/bind';

import SEO from '../components/seo';
import { BlogPost, BlogTag, SiteMetadata } from '../types/schema';
import { PostsList } from '../components/posts-list';
import IndexPage from '../components/index-page';
import styles from './blog-index.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

const cx = classNames.bind(styles);

type BlogIndexProps = PageProps<
  {
    site: {
      siteMetadata: Pick<SiteMetadata, 'title' | 'description'>;
    };
    allBlogPost: {
      totalCount: number;
      nodes: Array<
        Pick<BlogPost, 'title' | 'teaser' | 'slug' | 'date'> & {
          tags: Array<
            Pick<BlogTag, 'icon' | 'primaryColor' | 'secondaryColor'>
          >;
        }
      >;
    };
  },
  {
    skip: number;
    limit: number;
  }
>;

export const pageQuery = graphql`
  query BlogPostsForPage($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
        description
      }
    }

    allBlogPost(
      sort: { order: DESC, fields: [date] }
      filter: { draft: { ne: true } }
      skip: $skip
      limit: $limit
    ) {
      totalCount
      nodes {
        title
        teaser
        slug
        date
        tags {
          icon
          primaryColor
          secondaryColor
        }
      }
    }
  }
`;

export default function BlogIndexPage({
  data,
  location,
  pageContext,
}: BlogIndexProps) {
  let page = 1 + Math.floor(pageContext.skip / pageContext.limit);
  let totalPages =
    1 + Math.floor(data.allBlogPost.totalCount / pageContext.limit);

  let previousPage = page === 2 ? '/' : page > 2 ? `/${page - 1}` : undefined;
  let nextPage = page < totalPages ? `/${page + 1}` : undefined;

  return (
    <IndexPage
      title={data.site.siteMetadata.title}
      description={data.site.siteMetadata.description}
    >
      <SEO title="Home" location={location} />
      <PostsList posts={data.allBlogPost.nodes} />

      <div className={cx('pagination')}>
        {previousPage && (
          <Link to={previousPage} className={cx('navLink')}>
            <FontAwesomeIcon icon={faChevronLeft} /> Newer Posts
          </Link>
        )}
        <span className={cx('spacer')} />
        {nextPage && (
          <Link to={nextPage} className={cx('navLink')}>
            Older Posts <FontAwesomeIcon icon={faChevronRight} />
          </Link>
        )}
      </div>
    </IndexPage>
  );
}
