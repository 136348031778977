import React from 'react';
import { graphql, PageProps, Link } from 'gatsby';
import classNames from 'classnames/bind';

import ContentPage from '../components/content-page';
import SEO from '../components/seo';
import styles from './blog-post.module.css';
import PostSeparator from '../components/post-separator';
import {
  BlogPost as BlogPostNode,
  BlogTag,
  MarkdownRemark,
  SiteMetadata,
} from '../types/schema';

const cx = classNames.bind(styles);

type BlogPostProps = PageProps<{
  site: {
    siteMetadata: Pick<SiteMetadata, 'domain'>;
  };
  blogPost: Pick<
    BlogPostNode,
    'title' | 'slug' | 'teaser' | 'date' | 'author'
  > & {
    markdown: Pick<MarkdownRemark, 'html'>;
    tags: Array<
      Pick<
        BlogTag,
        'name' | 'icon' | 'primaryColor' | 'secondaryColor' | 'slug'
      >
    >;
  };
}>;

export const pageQuery = graphql`
  query BlogPostById($id: String!) {
    site {
      siteMetadata {
        domain
      }
    }

    blogPost(id: { eq: $id }) {
      title
      slug
      teaser
      date
      author
      tags {
        name
        icon
        primaryColor
        secondaryColor
        slug
      }
      markdown {
        html
      }
    }
  }
`;

export default function BlogPostPage({ data, location }: BlogPostProps) {
  let post = data.blogPost;
  let primaryTag = post.tags[0];
  let cardImage = `https://${data.site.siteMetadata.domain}/tag-images/${primaryTag.slug}.png`;

  return (
    <ContentPage>
      <SEO title={post.title} description={post.teaser} location={location}>
        <meta property="og:image" content={cardImage} />
        <meta property="og:image:width" content="200" />
        <meta property="og:image:height" content="200" />
      </SEO>

      <header className={cx('header')}>
        <span className={cx('info')}>
          {post.author} <span className={cx('infoDivider')}>|</span>{' '}
          <time className={cx('time')} dateTime={post.date}>
            {new Date(post.date).toLocaleDateString(undefined, {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
          </time>{' '}
          <span className={cx('infoDivider')}>|</span>{' '}
          {post.tags.map(tag => (
            <Link
              to={`/tags/${tag.slug}`}
              className={cx('tag')}
              title={tag.name}
              key={tag.slug}
            >
              {tag.icon}
            </Link>
          ))}
        </span>
        <h1 className={cx('title')}>{post.title}</h1>
      </header>
      <div className={cx('separator')}>
        <PostSeparator tag={primaryTag} />
      </div>
      <div
        className={cx('content')}
        dangerouslySetInnerHTML={{ __html: post.markdown.html }}
      />
    </ContentPage>
  );
}
