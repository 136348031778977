import React from 'react';
import { graphql, PageProps } from 'gatsby';

import SEO from '../components/seo';
import { BlogTag, MarkdownRemark, BlogPost } from '../types/schema';
import { PostsList } from '../components/posts-list';
import IndexPage from '../components/index-page';

export type BlogTagProps = PageProps<{
  blogTag: Pick<BlogTag, 'name'> & {
    markdown: Pick<MarkdownRemark, 'html'>;
    recentPosts: Array<
      Pick<BlogPost, 'slug' | 'title' | 'teaser' | 'date'> & {
        tags: Array<Pick<BlogTag, 'icon' | 'primaryColor' | 'secondaryColor'>>;
      }
    >;
  };
}>;

export const pageQuery = graphql`
  query BlogTagDetails($id: String!) {
    blogTag(id: { eq: $id }) {
      name
      markdown {
        html
      }
      recentPosts(limit: 24) {
        slug
        title
        teaser
        date
        tags {
          icon
          primaryColor
          secondaryColor
        }
      }
    }
  }
`;

export default function BlogTagPage({ data, location }: BlogTagProps) {
  return (
    <IndexPage
      title={data.blogTag.name}
      description={
        <div dangerouslySetInnerHTML={{ __html: data.blogTag.markdown.html }} />
      }
    >
      <SEO title={data.blogTag.name} location={location} />
      <PostsList posts={data.blogTag.recentPosts} />
    </IndexPage>
  );
}
