/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import type { WindowLocation } from '@reach/router';
import type { SiteMetadata } from '../types/schema';

export type SEOProps = {
  description?: string;
  lang?: string;
  title: string;
  location: WindowLocation;
  children?: React.ReactNode;
};

type SiteQueryResult = {
  site: {
    siteMetadata: Pick<
      SiteMetadata,
      'author' | 'description' | 'title' | 'domain'
    >;
  };
};

export default function SEO({
  lang = 'en',
  description,
  location,
  title,
  children,
}: SEOProps) {
  let { site } = useStaticQuery<SiteQueryResult>(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
          domain
        }
      }
    }
  `);

  let path = `${location.pathname.replace(/\/$/, '')}/`;
  let canonicalUrl = `https://${site.siteMetadata.domain}${path}`;
  let metaDescription = (description ?? site.siteMetadata.description).trim();

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
    >
      <meta name="description" content={metaDescription} />

      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="article" />

      <meta property="twitter:card" content="summary" />

      {children}
    </Helmet>
  );
}
